<template>
  <!-- content -->
  <div class="blog-detail-wrapper">
    <b-row>
      <!-- blogs -->
      <b-col cols="12">
        <b-card
            img-top
            img-alt="Файлы для импорта"
            title="Файлы для импорта"
        >
          <b-row>
            <b-col
                cols="12"
                md="12"
                lg="12"
                class="align-items-center justify-content-around mb-2 mb-md-0"
            >
              <b-row>
                <b-col md="12" lg="12" cols="12">
                  <b-list-group>
                    <b-list-group-item
                        v-for="file in list"
                        :key="file"
                        class="d-flex align-items-center"
                    >
                      <b-link
                          :to="{ name: 'movies-kinopub-import', params: { filename: file } }"
                          class="blog-title-truncate text-body-heading"
                      >
                        {{ file }}
                      </b-link>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

        </b-card>
      </b-col>
      <!--/ blogs -->
    </b-row>
    <!--/ blogs -->
  </div>
  <!--/ content -->
</template>

<script>
import {
  BFormInput, BMedia, BAvatar, BMediaAside, BMediaBody, BImg, BLink, BFormGroup, BInputGroup, BInputGroupAppend,
  BCard, BRow, BCol, BBadge, BCardText, BDropdown, BDropdownItem, BForm, BFormTextarea, BFormCheckbox, BButton,
  BTab,
  BTabs,
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BBadge,
    BCardText,
    BDropdown,
    BForm,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    BTab,
    BTabs,
    BListGroup, BListGroupItem
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      list: {},
      disabled: 0,
    }
  },
  created() {
    this.$http.get(`/movie/lists`).then(res => {
      this.list = res.data.data
    })
  },
  metaInfo: {
    title: 'Своя Игра ФНФ',
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
